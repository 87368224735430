import triple1 from "../../images/Rooms/TripleQuadruple/t1.jpg";
import triple2 from "../../images/Rooms/TripleQuadruple/t2.jpg";
import triple3 from "../../images/Rooms/TripleQuadruple/t3.jpg";
import triple4 from "../../images/Rooms/TripleQuadruple/t4.jpg";
import triple5 from "../../images/Rooms/TripleQuadruple/t5.jpg";
import triple6 from "../../images/Rooms/TripleQuadruple/t6.jpg";
import triple7 from "../../images/Rooms/TripleQuadruple/t7.jpg";
import triple8 from "../../images/Rooms/TripleQuadruple/t8.jpg";
import triple9 from "../../images/Rooms/TripleQuadruple/t9.jpg";
import triple10 from "../../images/Rooms/TripleQuadruple/t10.jpg";

import family1 from "../../images/Rooms/FamilyRoom/fr1.jpg";
import family2 from "../../images/Rooms/FamilyRoom/fr2.jpg";
import family3 from "../../images/Rooms/FamilyRoom/fr3.jpg";
import family4 from "../../images/Rooms/FamilyRoom/fr4.jpg";
import family5 from "../../images/Rooms/FamilyRoom/fr5.jpg";
import family6 from "../../images/Rooms/FamilyRoom/fr6.jpg";


import apartments16 from "../../images/Rooms/Apartment/a16.jpg";
import apartments17 from "../../images/Rooms/Apartment/a17.jpg";
import apartments18 from "../../images/Rooms/Apartment/a18.jpg";
import apartments19 from "../../images/Rooms/Apartment/a19.jpg";
import apartments20 from "../../images/Rooms/Apartment/a20.jpg";
import apartments21 from "../../images/Rooms/Apartment/a21.jpg";
import apartments22 from "../../images/Rooms/Apartment/a22.jpg";
import apartments23 from "../../images/Rooms/Apartment/a23.jpg";
import apartments24 from "../../images/Rooms/Apartment/a24.jpg";
import apartments25 from "../../images/Rooms/Apartment/a25.jpg";

import NorthernLights3 from "../../images/view-gallery/northern_lights3.jpg";
import NorthernLights2 from "../../images/view-gallery/northern_lights2.jpg";
import NorthernLights from "../../images/Surroundings/surrounding3.jpg";

import Neighborhood from "../../images/view-gallery/neighborhood.jpeg";
import Neighborhood2 from "../../images/view-gallery/neighborhood2.jpg";
import Neighborhood3 from "../../images/view-gallery/neighborhood3.jpg";

import Activities2 from "../../images/view-gallery/Activities/1.jpg";
import Activities3 from "../../images/view-gallery/Activities/2.jpg";
import Activities4 from "../../images/view-gallery/Activities/3.jpg";
import Activities from "../../images/view-gallery/Activities/4.jpg";

const RoomsData = [

  {
    title: "Triple/Quadruple",
    backgrounds: [
      triple1,
      triple2,
      triple3,
      triple4,
      triple5,
      triple7,
      triple8,
      triple9,
      triple10,
    ],
    description:
        "The Triple/Quadruple Rooms are located in two of the three buildings. They feature a sofa bed and flat screen TVs with Netflix. Twin beds can be requested depending on availability. Bathroom and kitchen facilities are shared.",
  },
  {
    title: "Family Room",
    backgrounds: [
      family2,
      family3,
      family5,
      family6,
    ],
    description:
        "The Family Room with a terrace overlooking the ocean and the lighthouse is located in the Grótta Northern Lights Apartment in Valhúsabraut 35. It includes a TV with Netflix, as well as a private bathroom and dining area. Kitchen facilities are shared.",
  },
  {
    title: "Three-bedroom Apartment",
    backgrounds: [
      apartments16,
      apartments17,
      apartments18,
      apartments19,
      apartments20,
      apartments21,
      apartments22,
      apartments23,
      apartments24,
      apartments25,
    ],
    description:
        "Two-Bedroom Bungalow Apartment located in the garden of the Blue House, this apartment is equipped with a kitchenette, private bathroom, sofa bed, as well as two TVs with Netflix.",
  },
];

const SurroundingsData = [
  {
    title: "Northern Lights",
    backgrounds: [NorthernLights, NorthernLights2, NorthernLights3],
    description:
        "Close to the Blue House, the best place to spot northern lights is by Grótta Lighthouse. Located on the edge of Seltjarnarnes Peninsula in a protected nature reserve home to more than 100 different bird species and 140 plant varieties, the lighthouse was built in 1897 and fully restored in 1947. You can easily reach it with a pleasant 25-minutes walk from the Blue House.",
  },
  {
    title: "Neighbourhood",
    backgrounds: [Neighborhood, Neighborhood2, Neighborhood3],
    description:
        "Seltjarnarnes is a very quiet town: you will definitely have a very relaxing stay with us! You will often see kids playing or running in the streets, parents walking with their toddlers, and cute, furry cats strolling around undisturbed. Did you know that in 2007 Seltjarnarnes became the world's first town where every citizen had access to fiber optics?",
  },
  {
    title: "Activities",
    backgrounds: [ Activities, Activities2, Activities3, Activities4],
    description:
        "While visiting Seltjarnarnes Peninsula, stroll through Valhúsahæð Park, behind the Blue House, then head to the Grótta Lighthouse, located in a protected nature reserve. Don’t miss the Kvika Foot Bath, a geothermal small pool that allows you to enjoy the beautiful view of Esja Mountain and Snæfellsjökull Glacier.",
  },
];

const customModalData = [RoomsData, SurroundingsData];

export default customModalData;
