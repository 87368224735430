import { Box, makeStyles } from '@material-ui/core';
import selfie from '../../images/selfie.jpg';
import { Element } from 'react-scroll';
import React from 'react';
/*import { Link } from 'react-router-dom';*/
import { WithTransLate } from '../../translating/index';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginTop: '130px',
		[theme.breakpoints.down('md')]: {
			width: 'fit-content',
			margin: '100px auto',
		},
		[theme.breakpoints.down('xs')]: {
			display: 'none',
		},
	},
	titleStyle: {
		fontFamily: 'Oblik',
		transform: 'rotate(-90deg)',
		color: '#003d3f',
		height: 'fit-content',
		width: 'fit-content',
		margin: '0 0 0 -58px',
		fontSize: '24px',
		fontWeight: '700',
		letterSpacing: '0.1em',
		marginTop: '-13px',
	},
	outLinedImage: {
		width: 'auto',
		height: '420px',
		padding: '2%',
		border: '1px solid',
		marginLeft: '-2%',
		[theme.breakpoints.down('md')]: {
			width: '525px',
			height: 'auto',
			marginLeft: '7vw;',
		},
	},
	selfieStyle: {
		width: '495px',
		height: '462px',
		marginTop: '-90px',
		[theme.breakpoints.up('lg')]: {
			width: '35vw',
		},
	},
	description: {
		paddingLeft : '50px',
		fontSize: '24px',
		fontWeight: 300,
		fontFamily: 'Josefin Sans',
		lineHeight: '30px',
		letterSpacing: '0.10em',
		textAlign: 'center',
		color: theme.palette.secondary.main,
	},
	descriptionWrapper: {
		alignItems: 'center',
		gap: '30px',
		width: '70vw',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		[theme.breakpoints.up('lg')]: {
			height: 'auto',
			width: '553.6px',
			gap: 'auto',
		},
	},
	content: {
		display: 'flex',
		flexGrow: 1,
		justifyContent: 'space-between',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			gap: '40px',
		},
	},
	but: {
		display: 'block',
		marginTop: '30px',
		color: theme.palette.secondary.main,
		[theme.breakpoints.down('xs')]: {
			width: '165px',
			height: '29px',
			fontFamily: 'Josefin Sans',
			fontStyle: 'normal',
			fontWeight: '400',
			fontSize: '16px',
			lineHeight: '20px',
		},
	},
}));

export default function AboutUs() {
	const {
		root,
		content,
		titleStyle,
		outLinedImage,
		selfieStyle,
		description,
		descriptionWrapper,
		but,
	} = useStyles();
	return (
		<Element id="aboutus">
			<Box className={root}>
				<h1 className={titleStyle}>
					<WithTransLate text="ABOUT US" />{' '}
				</h1>
				<div className={content}>
					<div className={outLinedImage}>
						<img className={selfieStyle} alt="selfie" src={selfie} />
					</div>
					<div className={descriptionWrapper}>
						<p className={description}>
							<WithTransLate text="Grótta Northern Lights Apartments welcomes you to your home away from home in Reykjavik, Iceland. Who are we? Simply put, we are a small team of globetrotters, passionate about unforgettable travel experiences." />
						</p>

						<a href="/About-us" className={clsx('outLinedButton', but)}>
							<WithTransLate text="DISCOVER MORE" />
						</a>
					</div>
				</div>
			</Box>
		</Element>
	);
}
