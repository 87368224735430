import React from "react";
import GuideLines from "./GuideLines.js";
import Instructions from "../../components/instructions/index.js";
import Support from "../../components/support.js";
import image from "../../images/houserules.jpg";
/*import image2 from "../../images/houserules_mobile.png";*/
import { makeStyles } from "@material-ui/core/styles";
import { WithTransLate } from "../../translating/index";

const useStyles = makeStyles((theme) => ({
  imageStyle: {
    width: "95%",
    height: "auto",
    marginLeft: "65px",
    marginBottom: "100px",
  },
  header: {
    display: "absolute",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  title: {
    transform: "rotate(-90deg)",
    height: "fit-content",
    width: "fit-content",
    margin: "80px -17px -118px -48px",
    fontFamily: "Oblik",
    fontSize: "24px",
    lineHeight: "50px",
    letterSpacing: "0.1em",
    color: "#003d3f",
    // marginRight: "auto",
    whiteSpace: "nowrap",
  },
  imageStyle2: {
    flexGrow: 1,
    maxWidth: "calc(100% - 0px)",
    // marginTop: "222px",
  },
  [theme.breakpoints.up("sm")]: {
    imageStyle2: {
      display: "none",
    },
  },
  [theme.breakpoints.down("xs")]: {
    header: {
      display: "flex",
      flexDirection: "row",
    },
    imageStyle: {
      display: "none",
    },
    houseRules: {
      marginTop: "22px",
    },
    title: {
      margin: "265px -40px 0 -37px",
      fontSize: "18px",
    },
  },
}));

function HouseRules() {
  const { imageStyle, title, /*imageStyle2,*/ houseRules, header } = useStyles();
  return (
    <div>
      <div className={header}>
        <p className={title}>
          <WithTransLate text="HOUSE RULES" />
        </p>
        {/* <img src={image2} alt="" className={imageStyle2} /> */}
        <img src={image} alt="" className={imageStyle} />
      </div>
      <div className={houseRules}>
        <GuideLines />
      </div>
      <Instructions />
      <Support removeInPhoneSize />
    </div>
  );
}

export default HouseRules;
