import React from "react";
import Phone from "../images/footer/phone.png";
import Home from "../images/footer/home.png";
import Mail from "../images/footer/mail.png";
import Map from "../images/footer/map.png";
import Whatsapp from "../images/footer/whatsapp.png";
import { useFormik } from "formik";
import * as yup from "yup";
import { makeStyles, TextField } from "@material-ui/core";
import { Link } from "react-router-dom";
import { WithTransLate } from "../../translating/index";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "black",
    display: "flex",
    gap: "calc( 30px + 2vw)",
    justifyContent: "space-around",
    flexWrap: "wrap",
    padding: "0 10.5vw 30px",
    marginTop: "-60px",
    fontFamily: "Myriad Pro",
    "& img": {
      marginRight: "0vw",
      marginTop: "-1.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      rowGap: "50px",
      padding: "0 10.5vw 100px",
      marginTop: "0px",
    },
  },
  newsLetter: {
    textAlign: "center",
    fontFamily: "Oblik",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "35px",
    letterSpacing: "0em",
    color: theme.palette.thrid.main,
  },

  signNLetter: {
    textAlign: "center",
    fontSize: "24px",
    fontWeight: 300,
    fontFamily: "Josefin Sans",
    lineHeight: "24px",
    letterSpacing: "0em",
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px !important",
      lineHeight: "14px !important",
      textAlign: "left",
      width: "105px",
    },
  },

  button: {
    fontWeight: "600",
    fontSize: "24px",
    fontHeight: "24px",
    fontFamily: "Josefin Sans",
    background: "#003d3f",
    width: "280px",
    height: "57px",
    left: "187px",
    color: "#FFFFFF",
    borderRadius: 0,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "240px",
      fontSize: "14px",
      lineHeight: "14px",
      fontWeight: 300,
      height: "40px",
    },
  },

  aboutus: {
    fontSize: "24px",
    fontWeight: 300,
    fontFamily: "Josefin Sans",
    lineHeight: "24px",
    letterSpacing: "0em",
    color: "black",
    [theme.breakpoints.down("xs")]: {
      fontSize: "14px !important",
      lineHeight: "14px !important",
      textAlign: "left",
      width: "105px",
    },
  },
  title: {
    fontFamily: "Oblik",
    fontSize: "30px",
    fontWeight: 700,
    lineHeight: "35px",
    letterSpacing: "0em",
    color: theme.palette.thrid.main,
    height: "35px",
    marginBottom: "17px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
      lineHeight: "21px",
      marginBottom: "-10px",
    },
  },

  contactInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
    "& a": {
      display: "flex",
      fontSize: "24px",
      fontWeight: 300,
      lineHeight: "20px",
      letterSpacing: "0em",
      marginLeft: "-12px",
    },
    "& p": {
      paddingLeft: "5px",
    },
    [theme.breakpoints.down("md")]: {
      order: 3,
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "30px",
      gap: "19px",
      "& img": {
        width: "15px",
      },
      "& a": {
        fontSize: "14px !important",
        lineHeight: "14px !important",
        marginLeft: "-7px",
      },
      "& p": {
        paddingLeft: "5px",
      },
    },
  },

  aboutUs: {
    display: "flex",
    flexDirection: "column",
    width: "14vw",
    fontFamily: "Josefin Sans",
    gap: "26px",
    [theme.breakpoints.down("md")]: {
      order: 1,
    },
  },
  form: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "280px",
    },
  },

  textField: {
    fontSize: "24px",
    fontWeight: 300,
    marginBottom: "20px",
    width: "280px",
    height: "57px",
    fontFamily: "Josefin Sans",
    lineHeight: "24px",
    textAlign: "center",
    "& .MuiOutlinedInput-input": {
      fontSize: "24px",
      fontWeight: 300,
      lineHeight: "24px",
      letterSpacing: "1px",
      wordSpacing: "2px",
      textAlign: "left",
      margin: "0 0 0 20px",
      borderRadius: 0,
      padding: "14.5px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "240px",
      fontSize: "14px",
      lineHeight: "14px",
      "& .MuiOutlinedInput-input": {
        padding: "13px 17px",
        fontSize: "14px",
        lineHeight: "14px",
        marginLeft: "40px",
      },
    },
  },

  highlightedColor: {
    color: "black",
  },

  signUp: {
    maxWidth: "345px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "2rem",
    "& p": {
      margin: "30px 0 20px",
    },
    [theme.breakpoints.down("md")]: {
      order: 2,
    },
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
      width: "240px",
      "& p": {
        margin: "18px 0px 10px",
        textAlign: "center",
        width: "240px",
        fontSize: "14px",
        lineHeight: "14px",
      },
    },
  },
}));

function Footer() {
  const {
    textField,
    signUp,
    newsLetter,
    signNewsLetter,
    root,
    form,
    button,
    aboutus,
    title,
    contactInfo,
    aboutUs,
    highlightedColor,
  } = useStyles();

  const validationSchema = yup.object({
    name: yup
      .string("Enter your full name")
      .min(4, "Too short!")
      .max(100, "Too Long!")
      .required("Your full name is required"),
    email: yup
      .string("Enter your email")
      .max(255, "Too Long!")
      .email("Enter a valid email")
      .required("Email is required"),
  });
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  return (
    <div data-aos="fade-up" id="footer" className={root}>
      <div className={contactInfo}>
        <h1 className={title}>
          <WithTransLate text="CONTACT US" />{" "}
        </h1>
        <a href="tel:+3547756480" className={highlightedColor}>
          <img src={Phone} alt="phone" />
          <p> +354 775 6480</p>
        </a>
        <a
          style={{
            fontWeight: "bold",
            textDecorationLine: "underline",
            fontSize: "18px",
          }}
          href="mailto:info@grottanorthernlights.com"
          target="_blank"
          rel="noreferrer"
          className={highlightedColor}
        >
          <img src={Mail} alt="mail" />
          <p>info@grottanorthernlights.com</p>
        </a>
        <a
          href="https://www.google.com/maps/place/Gr%C3%B3tta+Northern+Lights+Apartment+%26+Rooms/@64.1558256,-21.9991273,17z/data=!3m1!4b1!4m8!3m7!1s0x48d60bc5620ec2cd:0xefef4fc51c050a2a!5m2!4m1!1i2!8m2!3d64.1558286!4d-21.9969558"
          className={highlightedColor}
        >
          <img src={Map} alt="map" />
          <WithTransLate text="Valhúsabraut 19" /> <br />
          <WithTransLate text="Seltjarnarnes, 170, Iceland" />
        </a>
        <a
          href="https://bluehouse.is/"
          target="_blank"
          className={highlightedColor}
          rel="noreferrer"
        >
          <img src={Home} alt="home" />
          <p>
            <WithTransLate text="Blue House B & B" />
          </p>
        </a>
        <a
          href="https://api.whatsapp.com/send?phone=3547756480&text=&source=&data="
          target="_blank"
          className={highlightedColor}
          rel="noreferrer"
        >
          <img src={Whatsapp} alt="whats" fill="black" />
          <p> Whatsapp</p>
        </a>
      </div>
      <div className={signUp}>
        <h1 className={title}>
          <WithTransLate text="OUR NEWSLETTER" />
        </h1>
        <p className={aboutus}>
          <WithTransLate text="Sign up for our newsletter!" />
        </p>
        <form className={form} onSubmit={formik.handleSubmit}>
          <TextField
            placeholder={WithTransLate({
              text: "Your full name",
              isFunction: true,
            })}
            fullWidth
            id="filled-full-width"
            margin="normal"
            name="name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            // error={formik.touched.name && Boolean(formik.errors.name)}
            // helperText={formik.touched.name && formik.errors.name}
            className={textField}
          />
          {formik.errors.name ? (
            <div
              className="error"
              style={{ width: 200, height: 20, color: "red" }}
              helperText={formik.touched.name && formik.errors.name}
            >
              {formik.errors.name}
            </div>
          ) : null}
          <TextField
            placeholder={WithTransLate({
              text: "Your email address",
              isFunction: true,
            })}
            style={{ borderRadius: 0 }}
            fullWidth
            id="email"
            name="email"
            variant="outlined"
            value={formik.values.email}
            onChange={formik.handleChange}
            // error={formik.touched.email && Boolean(formik.errors.email)}
            // helperText={formik.touched.email && formik.errors.email}
            className={textField}
          />
          {formik.errors.email ? (
            <div
              className="error"
              style={{ width: 200, height: 20, color: "red" }}
              helperText={formik.touched.email && formik.errors.email}
            >
              {formik.errors.email}
            </div>
          ) : null}
          <button className={button} type="submit">
            <WithTransLate text="Sign up" />
          </button>
        </form>
      </div>
      <div className={aboutUs}>
        <h1 className={title}>
          <WithTransLate text="ABOUT US" />
        </h1>{" "}
        <a
          href="/house-rules"
          target="_blank"
          rel="noreferrer"
          className={aboutus}
        >
          <WithTransLate text="House Rules & Cancellation Policy" />
        </a>
        <a
          href="https://blog.bluehouse.is/"
          target="_blank"
          rel="noreferrer"
          className={aboutus}
        >
          <WithTransLate text="Blue House Blog" />
        </a>
        <a
          href="https://bluehouse.tourdesk.is/Tour/Item/18676/1/Northern_lights_-_Minibus"
          target="_blank"
          rel="noreferrer"
          className={aboutus}
        >
          <WithTransLate text="Northern Lights" />
        </a>
        <a
          href="https://bluehouse.tourdesk.is/Transportation"
          target="_blank"
          rel="noreferrer"
          className={aboutus}
        >
          <WithTransLate text="Airport Shuttle" />{" "}
        </a>
        <a
          href="https://gnl.ladesk.com/219394-Feedback"
          target="_blank"
          rel="noreferrer"
          className={aboutus}
        >
          <WithTransLate text="Feedback" />
        </a>
        <a
          href="https://bluehouse.is/privacy-and-policy"
          target="_blank"
          rel="noreferrer"
          className={aboutus}
        >
          <WithTransLate text="Imprint  &amp; Privacy Policy" />
        </a>
      </div>
    </div>
  );
}

export default Footer;
