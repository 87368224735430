import React, { useRef, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import Facebook from "../../images/Header_icons/headerSocialIcons/facebook.svg";
import Tripadvisor from "../../images/Header_icons/headerSocialIcons/tripadvisor_icon.svg";
import instaIcon from "../../images/Header_icons/headerSocialIcons/insta.svg";
import houserules_mobile from "../../images/houserules_mobile.png";
import headerLogoMobileSize from "../../images/headerLogoMobileSize.svg";
import Email from "../../images/Header_icons/headerSocialIcons/email.svg";
import Whatsapp from "../../images/Header_icons/headerSocialIcons/whats.svg";
import intro from "../../videos/intro.mp4";
import { WithTransLate } from "../../translating/index";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "space-around",
    width: "81vw",
    margin: "-55px auto 0",
    padding: "0",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  },
  sliderRoot: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    width: `80vw`,
    height: "590px",
    [theme.breakpoints.down("md")]: {
      width: `87vw`,
    },
    [theme.breakpoints.down("xs")]: {
      width: "calc(0vw - 0px)",
    },
  },
  "@media(max-width:660px)": {
    root: {
      marginTop: "10vh",
      marginLeft: "-43px",
      marginRight: "0px",
    },
  },
  gridList: {
    flexWrap: "nowrap",
    transform: "translateZ(0)",
    overflow: "hidden",
    scrollBehavior: "smooth",
    width: "75vw",
    height: "100%",
  },
  title: {
    color: theme.palette.primary.light,
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  gridListTile: {
    height: "100% !important",
    padding: "0 !important",
    width: "100%",
    backgroundSize: "cover",

    "@media(max-width:426px)": {
      display: "none",
    },
    "& video": {},
  },

  video: {
    width: "100%",
    height: "100%",
  },

  socialIcons: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: " 45px 0 0 0",
    gap: "31px",
    "& img": {
      width: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  iconz: {
    width: "20px",
    height: "20px",
  },
  whats: {
    width: "21.5px",
    height: "21px",
  },
  trip: {
    filter:
      "invert(29%) sepia(81%) saturate(393%) hue-rotate(136deg) brightness(97%) contrast(92%);",
  },
  emai: {
    width: "20px",
    height: "16px",
  },
  titleStyle: {
    fontFamily: "Oblik",
    transform: "rotate(-90deg)",
    whiteSpace: "nowrap",
    height: "fit-content",
    width: "fit-content",
    fontSize: "24px",
    fontWeight: "700",
    letterSpacing: "0.1em",
    margin: (props) => props.titleMargins,
    [theme.breakpoints.down("sm")]: {
      transform: "none",
      margin: "15px 0 -40px !important",
      width: "unset",
      height: "unset",
      textAlign: "center",
      padding: "0",
      marginBottom: "-35px",
      zIndex: 1,
    },
  },
  sliderReplacement: {
    width: "150vw",
    display: "none",
    backgroundSize: "cover",
    background: `url(${houserules_mobile})`,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& h1": {
      fontFamily: "Oblik",
      fontSize: "14px",
      fontWeight: "bold",
      textAlign: "center",
      color: "#fff",
      marginTop: "20px",
      marginBottom: "-15%",
      marginRight: "-150px",
    },
    "@media(max-width:426px)": {
      display: "flex",
      marginTop: "-460px",
      width: "140vw",
      height: "53vh",
    },
  },
}));

function Slider({ classes, title }) {
  const ref = useRef(null);
  const screenWidth = window.innerWidth;
  const offset =
    screenWidth > 1280
      ? screenWidth * 0.75
      : screenWidth > 600
      ? screenWidth * 0.87
      : screenWidth - 20;
  const videoDuration = 43000,
    imageDuration = 2500;

  const [indexValue, setIndexValue] = useState(0);
  const [duration, setDuration] = useState(videoDuration);
  const timeoutRef = React.useRef(null);
  const videoRef = React.useRef();

  try {
    ref.current.scrollLeft = indexValue * offset;
  } catch (error) {
    console.log(error);
  }

  const scroll = (isBack) => {
    isBack ? setIndexValue(indexValue - 1) : setIndexValue(indexValue + 1);
  };

  if (indexValue >= 7) {
    setIndexValue(indexValue === 0);
  } else if (indexValue < 0) {
    setIndexValue(indexValue === 0);
  }
  if (videoDuration > 43000) {
    setDuration(videoRef === 0);
  }

  return (
    <div className={classes.sliderRoot}>
      <ImageList ref={ref} className={classes.gridList} cols={1}>
        <ImageListItem key={"video"} className={classes.gridListTile}>
          <video loop className={classes.video} autoPlay muted>
            <source src={intro} type="video/mp4" />
          </video>
        </ImageListItem>
      </ImageList>
    </div>
  );
}

export default function Header({ title, titleMargins }) {
  const classes = useStyles({ titleMargins });
  return (
    <Box className={classes.root}>
      {title ? (
        <Typography className={classes.titleStyle}>
          <WithTransLate text={title} />
        </Typography>
      ) : (
        <Box className={classes.socialIcons}>
          <a
            href="https://www.instagram.com/grottanorthernlights/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={instaIcon} alt="instagram" className={classes.iconz} />
          </a>
          <a
            href="https://www.facebook.com/grottanorthernlights"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Facebook} alt="facebook" className={classes.iconz} />
          </a>
          <a
            href="https://api.whatsapp.com/send?phone=3547756480&text=&source=&data="
            target="_blank"
            rel="noreferrer"
          >
            <img src={Whatsapp} alt="whatsapp" className={classes.whats} />
          </a>
          <a
            href="https://www.tripadvisor.com/Hotel_Review-g189970-d19827773-Reviews-Grotta_Northern_Lights-Reykjavik_Capital_Region.html"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={Tripadvisor}
              alt="trip-advisor"
              className={classes.trip}
            />
          </a>
          <a
            href="mailto:info@grottanorthernlights.com"
            target="_blank"
            rel="noreferrer"
          >
            <img src={Email} alt="" className={classes.emai} />
          </a>
        </Box>
      )}
      <Box>
        <Slider title={title} classes={classes} />
        <div className={classes.sliderReplacement}>
          <img
            src={headerLogoMobileSize}
            alt="logo"
            style={{ marginLeft: 130 }}
          />
          <h1>
            <WithTransLate text="COLOUR YOUR EXPERIENCE" />
          </h1>
        </div>
      </Box>
    </Box>
  );
}
