

export const setUserInfo = info => ({
  type: 'SET_USERINFO',
  payload: info
});


export const setQuantity = q => ({
  type: 'SET_QUANTITY',
  payload: q
});


