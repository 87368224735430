

export const setSearchClicked = clicked => ({
    type: 'SEARCH_CLICKED',
    payload: clicked
  });
  
  /* export const addDates = item => ({
    type: SearchActionTypes.ADD_DATES,
    payload: item
  }); */
