/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import { Button, Divider, Grid, makeStyles } from "@material-ui/core";
import Logo from "../images/GrottaAuroraApartment.png";
import Bus from "../images/shuttle.svg";
import Escape from "../images/escape.svg";
import Door from "../images/door.svg";
import Tour from "../images/tour.svg";
import Gallery from "../images/gallery.svg";
import supportImage from "../images/support/whiteBackground_support.svg";
import forumImage from "../images/support/whiteBackground_forum.svg";
import feedbackImage from "../images/support/whiteBackground_feedback.svg";
import Car from "../images/car.svg";
import Bag from "../images/bag.svg";
import Info from "../images/info.svg";
import aboutus from "../images/aboutus.svg";
import Location from "../images/location.svg";
import Instagram from "../images/instagram.svg";
import Facebook from "../images/facebook.svg";
import Whatsapp from "../images/whatsApp.svg";
import Email from "../images/email.svg";
import { Link } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import TranslateMe from "../translating";
import { WithTransLate } from "../translating/index";
import { HashLink } from "react-router-hash-link";

const useStyles = makeStyles((theme) => ({
  root: {
    transitionDuration: "0.5s",
    position: "fixed",
    zIndex: "5",
    top: 0,
    right: (props) => props.right,
    bottom: 0,
    color: theme.palette.primary.main,
    padding: "25px 2vw",
    background: theme.palette.secondary.main,
    overflowX: "hidden",
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    maxWidth: "20vw",
    gap: "0px",
    [theme.breakpoints.down("md")]: {
      width: "30%",
      maxWidth: "none",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },

  sliderTitle: {
    fontSize: "18px",
    fontWeight: "700",
    lineHeight: "20px",
    padding: "10px 0 20px 0",
  },
  sliderLink: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "30px",
    cursor: "pointer",
  },
  sliderTitle2: {
    fontSize: "18px",
    fontWeight: "400",
    display: "flex",
    alignItems: "center",
    marginBottom: "22px",
  },
  horizontalLine: {
    width: "117%",
    margin: "20px 0 20px -35px",
    border: "0px solid rgba(255, 255, 255, 0.17)",
    background: "rgba(255, 255, 255, 0.17)",
    [theme.breakpoints.down("sm")]: {
      margin: "20px 0 10px -35px",
    },
  },
  sliderInfo: {
    fontSize: "18px",
    fontWeight: "400",
    lineHeight: "18px",
    margin: "8px 0",
  },
  sliderLogo: {
    width: "144px",
    marginBottom: "20px",
  },
  sliderEscape: {
    position: "absolute",
    right: "10px",
    width: "fit-content",
    padding: 0,
    "&:focus": {
      outline: "none",
    },
  },
  groupIcons: {
    display: "flex",
    justifyContent: "space-between",
    width: "170px",
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
    },
  },
  sliderIcons: {
    transform: "scale(1.3)",
    marginLeft: "-5px",
  },
  highlightedColor: {
    color: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.secondary.main,
    },
  },
  socialIcons: {
    transform: "scale(.9)",
  },
  linksWrapper: {
    padding: "25px 1vw",
  },
  contactus: {
    marginLeft: "10px",
  },
  feedback: {
    display: "none",
  },
  support: {
    display: "none",
  },
  forum: {
    display: "none",
  },
  [theme.breakpoints.down("sm")]: {
    root: {
      padding: "30px 6vw",
      maxHeight: "calc(100vh - 0px)",
    },
  },
  [theme.breakpoints.down("xs")]: {
    root: {
      maxWidth: "300px",
      right: "unset",
      left: (props) => props.right,
    },
    sliderTitle: {
      fontSize: "18px",
      lineHeight: "30px",
    },
    sliderLink: {
      fontSize: "16px",
    },
    recommendations: {},
    feedback: {
      display: "flex",
    },
    support: {
      display: "flex",
    },
    forum: {
      display: "flex",
    },
  },
}));

function SideNavbar(props) {
  const {
    recommendations,
    linksWrapper,
    root,
    sliderTitle,
    sliderLink,
    sliderInfo,
    sliderLogo,
    groupIcons,
    sliderTitle2,
    socialIcons,
    sliderIcons,
    sliderEscape,
    support,
    feedback,
    forum,
    horizontalLine,
    contactus,
    highlightedColor,
  } = useStyles(props);
  const { handleOpenAndCloseSideNavbar } = props;
  const scroll = () => handleOpenAndCloseSideNavbar("-600px");

  return (
    <div className={root}>
      <RouterLink onClick={scroll} to="/">
        <img
          src={Logo}
          alt="GróttaNorthernLights_logo"
          className={sliderLogo}
        />
      </RouterLink>
      <Button className={sliderEscape} onClick={scroll}>
        <img src={Escape} alt="GróttaNorthernLights_logo" />
      </Button>
      <TranslateMe scroll={scroll} />
      <Divider className={horizontalLine} />
      <Grid className={linksWrapper} container spacing={3}>
        <Grid
          container
          item
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <p className={sliderTitle}>
            <WithTransLate text="BOOKINGS AND RENTAL" />{" "}
          </p>
        </Grid>
        <Grid
          onClick={scroll}
          component={"a"}
          href="#1"
          container
          item
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item>
            <img className={sliderIcons} src={Bus} alt="" />
          </Grid>
          <Grid item>
            <a
              href="https://bluehouse.tourdesk.is/Transportation"
              target="_blank"
              rel="noreferrer"
              className={highlightedColor}
            >
              {" "}
              <p className={sliderLink}>
                <WithTransLate text="Book Airport Shuttle" />
              </p>{" "}
            </a>
          </Grid>
        </Grid>
        <Grid
          onClick={scroll}
          component={"a"}
          href="#2"
          container
          item
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid item>
            <img className={sliderIcons} src={Door} alt="" />
          </Grid>
          <Grid item>
            <a
              href="https://www.beds24.com/booking2.php?propid=76255"
              target="_blank"
              rel="noreferrer"
              className={highlightedColor}
            >
              {" "}
              <p className={sliderLink}>
                <WithTransLate text="Book a Room" />
              </p>{" "}
            </a>
          </Grid>
        </Grid>
        <Grid onClick={scroll} container item spacing={2} alignItems="center">
          <Grid item>
            <img className={sliderIcons} src={Tour} alt="" />
          </Grid>
          <Grid item>
            <a
              href="https://bluehouse.tourdesk.is/Tour"
              target="_blank"
              rel="noreferrer"
              className={highlightedColor}
            >
              <p className={sliderLink}>
                <WithTransLate text="Book Day Tours" />
              </p>
            </a>
          </Grid>
        </Grid>
        <Grid onClick={scroll} container item spacing={2} alignItems="center">
          <Grid item>
            <img className={sliderIcons} src={Car} alt="" />
          </Grid>
          <Grid item>
            <a
              href="https://bluehouse.tourdesk.is/CarRental"
              target="_blank"
              rel="noreferrer"
              className={highlightedColor}
            >
              <p className={sliderLink}>
                <WithTransLate text="Rent a Car" />
              </p>
            </a>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <p className={sliderTitle}>
            <WithTransLate text="GENERAL INFORMATION" />
          </p>
        </Grid>
        <Grid
          className={highlightedColor}
          component={RouterLink}
          onClick={scroll}
          to="/view-gallery"
          duration={500}
          offset={-150}
          container
          item
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <img className={sliderIcons} src={Gallery} alt="" />
          </Grid>
          <Grid item>
            <p className={sliderLink}>
              <WithTransLate text="Gallery" />
            </p>
          </Grid>
        </Grid>
        <HashLink
          to="/#recommendations"
          className={highlightedColor}
          activeClassName={recommendations}
          scroll={(el) => el.scrollIntoView({ behavior: "auto", block: "end" })}
          style={{ display: "flex", flexDirection: "row" }}
        >
          <Grid item>
            <img
              className={sliderIcons}
              src={Bag}
              alt=""
              style={{ marginLeft: "20%" }}
            />
          </Grid>
          <Grid item>
            <p className={sliderLink} style={{ marginLeft: "16%" }}>
              <WithTransLate text="Recommendations" />
            </p>
          </Grid>
        </HashLink>
        <Grid
          container
          item
          spacing={2}
          alignItems="center"
          component={RouterLink}
          onClick={scroll}
          to="/About-Us"
          duration={500}
          offset={-200}
          className={highlightedColor}
        >
          <Grid item>
            <img className={sliderIcons} src={aboutus} alt="" />
          </Grid>
          <Grid item>
            <p className={sliderLink}>
              <WithTransLate text="About us" />
            </p>
          </Grid>
        </Grid>

        <p className={sliderTitle}>
          <WithTransLate text="HELP AND SUPPORT" />
        </p>
        <Grid
          component={Link}
          onClick={scroll}
          to="FAQ"
          duration={500}
          offset={-145}
          container
          item
          spacing={2}
          alignItems="center"
          justifyContent="flex-start"
        >
          <Grid onClick={scroll} container item spacing={2} alignItems="center">
            <Grid item>
              <img className={sliderIcons} src={Info} alt="" />
            </Grid>
            <Grid item>
              <p className={sliderLink}>
                <WithTransLate text="FAQ" />
              </p>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          className={support}
          onClick={scroll}
          container
          item
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <img className={sliderIcons} src={supportImage} alt="" />
          </Grid>
          <Grid item>
            <a
              href="https://gnl.ladesk.com/"
              target="_blank"
              rel="noreferrer"
              className={highlightedColor}
            >
              <p className={sliderLink}>
                <WithTransLate text="Support" />
              </p>
            </a>
          </Grid>
        </Grid>
        <Grid
          className={forum}
          onClick={scroll}
          container
          item
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <img className={sliderIcons} src={forumImage} alt="" />
          </Grid>
          <Grid item>
            <a
              href="https://support.bluehouse.is/105159-Forum"
              target="_blank"
              rel="noreferrer"
              className={highlightedColor}
            >
              <p className={sliderLink}>
                <WithTransLate text="Forum" />
              </p>
            </a>
          </Grid>
        </Grid>
        <Grid
          className={feedback}
          onClick={scroll}
          container
          item
          spacing={2}
          alignItems="center"
        >
          <Grid item>
            <img className={sliderIcons} src={feedbackImage} alt="" />
          </Grid>
          <Grid item>
            <a
              href="https://gnl.ladesk.com/219394-Feedback"
              target="_blank"
              rel="noreferrer"
              className={highlightedColor}
            >
              <p className={sliderLink}>
                <WithTransLate text="Feedback" />
              </p>
            </a>
          </Grid>
        </Grid>
        <Grid
          container
          item
          spacing={2}
          alignItems="center"
          component={Link}
          onClick={scroll}
          to="Map"
          duration={500}
          offset={-140}
        >
          <Grid item>
            <img className={sliderIcons} src={Location} alt="" />
          </Grid>
          <Grid item>
            <p className={sliderLink}>
              <WithTransLate text="Map" />
            </p>
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" className={contactus}>
        <p className={sliderTitle2}>
          <WithTransLate text="Contact us" />
        </p>
        <div className={groupIcons}>
          <a
            onClick={scroll}
            href="https://www.instagram.com/grottanorthernlights/"
            target="_blank"
            rel="noreferrer"
          >
            <img className={socialIcons} src={Instagram} alt="" />
          </a>
          <a
            onClick={scroll}
            href="https://www.facebook.com/grottanorthernlights"
            target="_blank"
            rel="noreferrer"
          >
            <img className={socialIcons} src={Facebook} alt="" />
          </a>
          <a
            onClick={scroll}
            href="https://api.whatsapp.com/send?phone=3547756480&text=&source=&data="
            target="_blank"
            rel="noreferrer"
          >
            <img className={socialIcons} src={Whatsapp} alt="" />
          </a>
          <a
            onClick={scroll}
            href="https://gnl.ladesk.com/submit_ticket"
            target="_blank"
            rel="noreferrer"
          >
            <img className={socialIcons} src={Email} alt="" />
          </a>
        </div>
        <Divider className={horizontalLine} />
        <p className={sliderInfo}>Grótta Northern Lights Apartment</p>
        <a
          onClick={scroll}
          href="mailto:info@grottanorthernlights.com"
          target="_blank"
          rel="noreferrer"
          className={highlightedColor}
        >
          <p className={sliderInfo}>info@grottanorthernlights.com</p>{" "}
        </a>
        <a onClick={scroll} href="tel:+3547756480">
          <p className={highlightedColor}>+354 775 6480</p>
          {/* <p className={sliderInfo} className={highlightedColor}>
						+354 775 6480
					</p> */}
        </a>
      </Grid>
    </div>
  );
}

export default SideNavbar;
