import React from 'react';
import { makeStyles } from '@material-ui/core';
// import Selfie from '../images/selfie2.png';
import Selfie from '../images/selfiereplace.jpg';
import Shape1 from '../images/Shape.svg';
import Signature from '../images/signature.png';
import colorYourExperience from '../images/aboutus/colorYourExperience.png';
import message from '../images/aboutus/message.png';
import Room from '../images/grotta.jpg';
import Reviews from '../components/reviews';
import clsx from 'clsx';
import { WithTransLate } from '../translating/index';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'absolute',
		flexDirection: 'column',
		justifyContent: 'center',
		alignContent: 'center',
		margin: 'auto',
	},
	row1: {
		display: 'flex',
		marginTop: '0px',
	},
	row2: {
		display: 'flex',
		marginTop: '0px',
	},
	description1: {
		backgroundImage: `url(${Shape1})`,
		backgroundSize: 'cover',
		fontSize: '16px',
		lineHeight: '30px',
		fontWeight: '300',
		width: 'calc(50% - 30px)',
		letterSpacing: '0.1em',
		marginLeft: '50px',
		color: theme.palette.secondary.main,
	},
	image1: {
		width: '50%',
		height: '88%',
	},
	description2: {
		width: 'calc(50% - 100px)',
		marginLeft: '0px',
		backgroundImage: `url(${Shape1})`,
		backgroundSize: 'cover',
		fontWeight: '300',
		marginRight: '0px',
		height: 'auto',
		fontSize: '16px',
		lineHeight: '30px',
		letterSpacing: '0.1em',
		color: '#003d3f',
	},
	image2: {
		width: 'calc(50% - 40px)',
		marginLeft: '40px',
		height: 'fit-content',
	},
	titles: {
		fontFamily: 'Oblik',
		whiteSpace: 'nowrap',
		transform: 'rotate(-90deg)',
		height: '24px',
		width: '24px',
		fontSize: '24px',
		letterSpacing: '0.1em',
		color: '#003d3f',
	},
	experienceTitle: {
		margin: '353px 30px 0px 0px',
	},
	founderMessageTitle: {
		margin: '387px 0px 0px -70px',
	},
	signature: {
		// border: '1px solid red',
		maxWidth: '200px',
		marginTop: '37px',
	},
	selfie: {
		width: '100%',
		maxHeight: '70.5vh',
	},
	imageAndDescriptionWrapper: {
		display: 'flex',
	},
	[theme.breakpoints.down('md')]: {
		imageAndDescriptionWrapper: {
			flexDirection: 'column',
			width: 'fit-content',
			maxWidth: '514px',
		},
		root: {
			marginTop: '-300px',
		},
		image1: {
			width: 'fit-content',
			height: 'fit-content',
			padding: '60% 0 0 0',
		},
		image2: {
			width: 'unset',
		},
		description2: {
			order: 2,
			width: '100%',
			margin: '40px 0',
			textAlign: 'left',
		},
		description1: {
			width: '100%',
			margin: '40px 0',
			textAlign: 'left',
		},
		founderMessageTitle: {
			margin: '387px 0px 0px 30px',
		},
		row1: {
			justifyContent: 'center',
		},
		row2: {
			justifyContent: 'center',
		},
		signature: {
			maxWidth: '150px',
			marginTop: '20px',
		},
		experienceTitle: {
			margin: '635px 30px 0px 0px',
		},
	},
	[theme.breakpoints.down('xs')]: {
		image1: {
			content: `url(${colorYourExperience})`,
		},
		image2: {
			content: `url(${message})`,
		},
		description1: {
			fontWeight: 300,
			marginLeft: '-25px',
		},
		description2: {
			fontWeight: 300,
			marginLeft: '25px',
		},
		titles: {
			fontSize: '16px',
			letterSpacing: 'fit-content',
		},
		row1: {
			marginTop: 'unset',
		},
		row2: {
			marginTop: 'unset',
		},
		founderMessageTitle: {
			margin: '250px 20px -9px 15px',
		},
		experienceTitle: {
			margin: '442px 10px 0px 15px',
		},
	},
}));

function Aboutus() {
	const {
		root,
		experienceTitle,
		image1,
		description1,
		description2,
		image2,
		founderMessageTitle,
		row1,
		row2,
		selfie,
		imageAndDescriptionWrapper,
		signature,
		titles,
	} = useStyles();
	return (
		// <>
		<div className={root}>
			<div className={row1}>
				<h1 className={clsx(titles, experienceTitle)}>
					<WithTransLate text=" COLOUR YOUR EXPERIENCE" />
				</h1>
				<div className={imageAndDescriptionWrapper}>
					<img src={Room} className={image1} alt="roomImg" />
					<div className={description1}>
						<p>
							{' '}
							<WithTransLate
								text="Grótta Northern Lights Apartment team welcomes you to your home away from home in Reykjavik, Iceland.
              We are young globetrotters passionate about unforgettable travel adventures, and we are sure at the Blue House you will feel at home surrounded by 
              the friends you never knew you had."
							/>
						</p>
						<p>
							<br />
							<WithTransLate
								text="Peacefully set on Seltjarnarnes Peninsula, the Grótta Northern Lights Apartment is a 5 minute drive from Reykjavik’s vibrant downtown and 20 minutes’ walk
                from the “Grótta Lighthouse”, the perfect spot to catch northern lights."
							/>
						</p>
						<p>
							<br />
							<WithTransLate text="The property is allocated over three houses and guests can choose from guestrooms or self-catering apartments. Parking is free of charge." />
						</p>
					</div>
				</div>
			</div>
			<div className={row2}>
				<div className={imageAndDescriptionWrapper}>
					<div className={description2}>
						<p>
							<WithTransLate text=" WELCOMING GUESTS FROM ALL OVER THE WORLD SINCE 2016" />
						</p>
						<p>
							<br />
							<WithTransLate text=" Welcome to your home of great adventures." />
						</p>
						<p>
							<br />
							<WithTransLate
								text=" Our Grótta Northern Apartment and Rooms allow for a gorgeous sea view, as well as the
                Grótta Lighthouse with the Snæfellsnes volcano behind. 150 meters away, you can find our beautiful 
                Blue House, where you can enjoy the self-service breakfast. If you want to explore the culture of Iceland’s 
                capital Reykjavik, a nice walk to the Downtown area is all it takes to be surrounded by a variety of museums, stores, restaurants and bars."
							/>
						</p>
						<p>
							<WithTransLate text=" For any recommendations or tips prior to your arrival, you can always use the live chat option below. We also promise you " />
							<b>
								<WithTransLate text=" the cheapest price if you book on our website right here." />
							</b>
						</p>
						<div>
							<img
								alt="Signature img"
								className={signature}
								src={Signature}
							/>
						</div>
					</div>
					<div className={image2}>
						<img alt="Founder img" className={selfie} src={Selfie} />
						{/* <img
							alt="Signature img"
							className={signature}
							src={Signature}
						/> */}
					</div>
				</div>
				<h1 className={clsx(titles, founderMessageTitle)}>
					<WithTransLate text=" MESSAGE FROM THE FOUNDER" />
				</h1>
			</div>
			<Reviews />
		</div>
	);
}

export default Aboutus;
