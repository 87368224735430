import {
	makeStyles,
	Typography,
	ImageList,
	ImageListItem,
} from '@material-ui/core';
import insta1 from '../../images/instagram/insta1.jpg';
import insta2 from '../../images/instagram/insta2.jpg';
import insta3 from '../../images/instagram/insta3.jpg';
import insta4 from '../../images/instagram/insta4.jpg';
/* import insta5 from '../../images/instagram/insta5.jpg';
import insta6 from '../../images/instagram/insta6.jpg';
import insta7 from '../../images/instagram/insta7.jpg';
import insta8 from '../../images/instagram/insta8.jpg';
import insta9 from '../../images/instagram/insta9.jpg';
import insta10 from '../../images/instagram/insta10.jpg'; */
import { useEffect, useRef, useState } from 'react';
import next from '../../images/reviews/next.svg';
import back from '../../images/reviews/back.svg';
import clsx from 'clsx';
import { WithTransLate } from '../../translating/index';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		marginTop: 70,
		width: '100%',
		[theme.breakpoints.down('xs')]: {
			overflowY: 'hidden',
			overflowX: 'auto',
			paddingLeft: '30px',
			width: 'unset',
			height: '30%',
		},
	},
	photoWrapper: {
		width: '21vw',
		height: '50vh',
		backgroundSize: 'cover',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-evenly',
		[theme.breakpoints.down('xs')]: {
			width: '221px',
			height: '193px',
		},
	},

	imagesRoot: {
		display: 'flex',
		justifyContent: 'space-between',
		flexGrow: 1,
		flexDirection: 'column',
		maxWidth: 'calc(100%)',
		[theme.breakpoints.down('xs')]: {
			width: '221px',
			height: '193px',
		},
	},

	titleStyle: {
		fontFamily: 'Oblik',
		transform: 'rotate(-90deg)',
		color: '#003d3f',
		height: 'fit-content',
		width: 'fit-content',
		fontSize: '24px',
		fontWeight: '700',
		lineHeight: '50px',
		letterSpacing: '0.1em',
		margin: '50px -40px 0 -70px',
		[theme.breakpoints.down('xs')]: {
			margin: '35px -30px 0 -55px',
			fontSize: '18px',
		},
	},

	category: {
		'font-size': '22px',
		'font-weight': '600',
		'line-height': '50px',
		'letter-spacing': '0em',
	},

	actionStyle: {
		margin: ' 0  auto',
		display: 'flex',
		justifyContent: 'center',
	},
	but: {
		display: 'block',
		marginTop: '20px',
	},
	gridList: {
		flexWrap: 'nowrap',
		transform: 'translateZ(0)',
		overflow: 'hidden',
		scrollBehavior: 'smooth',
		gap: '6vw',
	},
	gridListTile: {
		height: '25vh',
		minHeight: '361px',
		'@media(max-width:426px)': {
			height: '25vh',
			minHeight: '195px',
		},
	},
	controlsButtons: {
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '-30px',
		marginRight: '10px',
	},
	followButton: {
		margin: '0 0 0 40px',
		width: '145px',
		height: '49px',
		fontFamily: 'Josefin Sans',
		fontWeight: 600,
		fontSize: '18px',
		lineHeight: '24px',
		color: theme.palette.thrid.main,
		border: '1px solid #003d3f',
		boxsizing: 'border-box',
	},
	control: {
		padding: '0 12.5px',
		background: 'none',
		border: 'none',
	},

	[theme.breakpoints.down('xs')]: {
		root: {
			marginTop: '50px',
		},
		controlsButtons: {
			display: 'none',
		},
		gridList: {
			overflow: 'auto',
		},
		gridListTile: {
			height: '20vh',
		},
		followButton: {
			width: '164px',
			height: '29px',
			fontFamily: 'Josefin Sans',
			fontStyle: 'normal',
			fontWeight: 'normal',
			fontSize: '16px',
			lineHeight: '20px',
			margin: '0 0 0 -10px',
			textAlign: 'center',
		},
	},
}));

const instagramGalleryData = [
	{
		imageUrl: insta3,
	},
	{
		imageUrl: insta2,
	},
	{
		imageUrl: insta4,
	},
	// {
	//   imageUrl: insta6,
	// },
	// {
	//   imageUrl: insta5,
	// },
	// {
	//   imageUrl: insta8,
	// },
	// {
	//   imageUrl: insta7,
	// },
	// {
	//   imageUrl: insta9,
	// },
	{
		imageUrl: insta1,
	},
	// {
	//   imageUrl: insta10,
	// },
];
export default function InstagramGallery({ handleOnClick }) {
	const [scrollOffSet, setScrollOffSet] = useState(0);
	//const [gapBetweenImages, setGapBetweenImages] = useState(.06 * window.innerWidth)
	const insta = useRef();
	const gapBetweenImages = 0.06 * window.innerWidth;
	useEffect(() => {
		if (insta.current) {
			const imageWidth = insta.current.firstChild.clientWidth;
			const parentWidth = insta.current.clientWidth;
			const imagesNumber = parentWidth / (imageWidth + gapBetweenImages / 3);
			setScrollOffSet(
				parseInt(imagesNumber) * (imageWidth + gapBetweenImages)
			);
		}
	}, [insta, setScrollOffSet, gapBetweenImages]);
	/*window.addEventListener('resize', () => {
        setGapBetweenImages(.06 * window.innerWidth)
    });*/
	const {
		gridListTile,
		controlsButtons,
		control,
		gridList,
		photoWrapper,
		imagesRoot,
		root,
		titleStyle,
		followButton,
	} = useStyles();
	return (
		<div>
			<div className={root}>
				<Typography className={titleStyle}>
					<WithTransLate text="INSTAGRAM" />{' '}
				</Typography>
				<div className={imagesRoot}>
					<ImageList ref={insta} className={gridList} cols={0}>
						{instagramGalleryData.map(({ imageUrl }, index) => (
							<ImageListItem className={gridListTile} key={index}>
								<a
									href="https://www.instagram.com/bluehousebb/"
									// className={clsx(followButton, "outLinedButton")}
									target="_blank"
									rel="noreferrer"
								>
									<div
										className={photoWrapper}
										style={{ backgroundImage: `url(${imageUrl})` }}
									></div>
								</a>
							</ImageListItem>
						))}
					</ImageList>
				</div>
			</div>
			<div
				style={{
					display: 'flex',
					marginTop: '40px',
					justifyContent: 'center',
				}}
			>
				<a
					href="https://www.instagram.com/bluehousebb/"
					className={clsx(followButton, 'outLinedButton')}
					target="_blank"
					rel="noreferrer"
				>
					<WithTransLate text="FOLLOW US" />
				</a>
			</div>
			<div className={controlsButtons}>
				<button
					onClick={() => {
						insta.current.scrollLeft -= scrollOffSet;
					}}
					className={control}
				>
					<img alt="back" src={back} />
				</button>
				<button
					className={control}
					onClick={() => {
						insta.current.scrollLeft += scrollOffSet;
					}}
				>
					<img alt="next" src={next} />
				</button>
			</div>
		</div>
	);
}
