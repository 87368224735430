import { createTheme } from '@material-ui/core/styles';
const theme = createTheme({
	palette: {
		primary: { main: '#FFFFFF' },
		// secondary: { main: "#073762" },
		secondary: { main: '#1b686e' },
		thrid: { main: '#000000' },
	},
	text: {
		primary: 'white',
		secondary: '#003d3f',
	},
});
export default theme;
